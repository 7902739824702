// core
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import LayoutEmbeddedPage from '../components/LayoutEmbeddedPage'
import { Seo } from '../components/Seo'

const Findyourflavor = ({data}) => {
  // console.log('data ' , data)
  const pageContent = data.prismicFullscreenEmbeddedPage
  const pageData = data.prismicFullscreenEmbeddedPage.data
  const isBrowser = typeof window !== "undefined"

  const { type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const lang = "en-us"

  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  useEffect(() => {
    if (isBrowser) {
      const div = document.createElement('div');
      div.className = 'btm-embed';
      div.setAttribute('data-campaign', '2024-white-claw');

      const script = document.createElement('script');
      script.src = 'https://assets.dailybreak.com/embed/v9.js';
      script.async = true;
      script.defer = true;

      const firstChild = document.body.firstChild;
      document.body.insertBefore(div, firstChild);
      document.body.insertBefore(script, div.nextSibling);

      return () => {
        document.body.removeChild(div);
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
      <LayoutEmbeddedPage currentPage="findyourflavor">
        <Seo
            title={pageData.meta_title?.text}
            description={pageData.meta_description?.text}
            social_card={pageData.social_card?.url}
        />
      </LayoutEmbeddedPage>
  );
}

export const query = graphql`
query FindyourflavorPageQuery{
    prismicFullscreenEmbeddedPage (uid: {eq: "findyourflavor"}) {
    _previewable
    url
    type
    id
    lang
       data {
        meta_title {
            text
        }
        social_card{
          url
        }
        meta_description {
          text
        }
       }
    }
}
`
export default withPrismicPreview(Findyourflavor)